import {
  IStyleParam,
  IStyleParams,
  StyleParamType,
  wixColorParam,
  wixFontParam,
  createStylesParams,
} from '@wix/tpa-settings';

export type IComponentStyles = IStyleParams<{
  headerHeight: IStyleParam<StyleParamType.Number>;
  headerSidePadding: IStyleParam<StyleParamType.Number>;
  headerImageOpacity: IStyleParam<StyleParamType.Number>;
  headerBackgroundColor: IStyleParam<StyleParamType.Color>;
  headerTitleFont: IStyleParam<StyleParamType.Font>;
  headerTitleColor: IStyleParam<StyleParamType.Color>;
  bodyBackgroundColor: IStyleParam<StyleParamType.Color>;
  bodyServiceTitleFont: IStyleParam<StyleParamType.Font>;
  bodyServiceTitleColor: IStyleParam<StyleParamType.Color>;
  bodyServiceTaglineFont: IStyleParam<StyleParamType.Font>;
  bodyServiceTaglineColor: IStyleParam<StyleParamType.Color>;
  bodyTitleFont: IStyleParam<StyleParamType.Font>;
  bodyTitleColor: IStyleParam<StyleParamType.Color>;
  bodyTextFont: IStyleParam<StyleParamType.Font>;
  bodyTextColor: IStyleParam<StyleParamType.Color>;
  bodyDividerWidth: IStyleParam<StyleParamType.Number>;
  bodyDividerColor: IStyleParam<StyleParamType.Color>;
  sidebarBackgroundColor: IStyleParam<StyleParamType.Color>;
  sidebarTitleColor: IStyleParam<StyleParamType.Color>;
  sidebarTitleFont: IStyleParam<StyleParamType.Font>;
  sidebarTextColor: IStyleParam<StyleParamType.Color>;
  sidebarTextFont: IStyleParam<StyleParamType.Font>;
  sidebarServiceTitleColor: IStyleParam<StyleParamType.Color>;
  sidebarServiceTitleFont: IStyleParam<StyleParamType.Font>;
  sidebarServiceTaglineColor: IStyleParam<StyleParamType.Color>;
  sidebarServiceTaglineFont: IStyleParam<StyleParamType.Font>;
  sidebarButtonTextColor: IStyleParam<StyleParamType.Color>;
  sidebarButtonTextFont: IStyleParam<StyleParamType.Font>;
  sidebarButtonBackgroundColor: IStyleParam<StyleParamType.Color>;
  sidebarButtonBorderColor: IStyleParam<StyleParamType.Color>;
  sidebarButtonBorderWidth: IStyleParam<StyleParamType.Number>;
  sidebarButtonCornerRadius: IStyleParam<StyleParamType.Number>;
  sidebarButtonHoverTextColor: IStyleParam<StyleParamType.Color>;
  sidebarButtonHoverBackgroundColor: IStyleParam<StyleParamType.Color>;
  sidebarButtonHoverBorderColor: IStyleParam<StyleParamType.Color>;
  sidebarSidePadding: IStyleParam<StyleParamType.Number>;
  headerButtonTextColor: IStyleParam<StyleParamType.Color>;
  headerButtonTextFont: IStyleParam<StyleParamType.Font>;
  headerButtonBackgroundColor: IStyleParam<StyleParamType.Color>;
  headerButtonBorderColor: IStyleParam<StyleParamType.Color>;
  headerButtonBorderWidth: IStyleParam<StyleParamType.Number>;
  headerButtonCornerRadius: IStyleParam<StyleParamType.Number>;
  headerButtonHoverTextColor: IStyleParam<StyleParamType.Color>;
  headerButtonHoverBackgroundColor: IStyleParam<StyleParamType.Color>;
  headerButtonHoverBorderColor: IStyleParam<StyleParamType.Color>;
  bodyButtonTextColor: IStyleParam<StyleParamType.Color>;
  bodyButtonTextFont: IStyleParam<StyleParamType.Font>;
  bodyButtonBackgroundColor: IStyleParam<StyleParamType.Color>;
  bodyButtonBorderColor: IStyleParam<StyleParamType.Color>;
  bodyButtonBorderWidth: IStyleParam<StyleParamType.Number>;
  bodyButtonCornerRadius: IStyleParam<StyleParamType.Number>;
  bodyButtonHoverTextColor: IStyleParam<StyleParamType.Color>;
  bodyButtonHoverBackgroundColor: IStyleParam<StyleParamType.Color>;
  bodyButtonHoverBorderColor: IStyleParam<StyleParamType.Color>;
  bodySidePadding: IStyleParam<StyleParamType.Number>;
  bodySpaceBetweenSections: IStyleParam<StyleParamType.Number>;
  firstSectionSpaceFromTheTop: IStyleParam<StyleParamType.Number>;
  serviceTitleFont: IStyleParam<StyleParamType.Font>;
  serviceTitleColor: IStyleParam<StyleParamType.Color>;
  serviceTaglineFont: IStyleParam<StyleParamType.Font>;
  serviceTaglineColor: IStyleParam<StyleParamType.Color>;
  backgroundColor: IStyleParam<StyleParamType.Color>;
  detailsInnerPadding: IStyleParam<StyleParamType.Number>;
  detailsBoxFont: IStyleParam<StyleParamType.Font>;
  detailsBoxTextColor: IStyleParam<StyleParamType.Color>;
  detailsBoxColor: IStyleParam<StyleParamType.Color>;
  detailsBoxBorderColor: IStyleParam<StyleParamType.Color>;
  detailsBoxBorderWidth: IStyleParam<StyleParamType.Number>;
}>;

export default createStylesParams<{
  headerHeight: StyleParamType.Number;
  headerSidePadding: StyleParamType.Number;
  headerImageOpacity: StyleParamType.Number;
  headerBackgroundColor: StyleParamType.Color;
  headerTitleFont: StyleParamType.Font;
  headerTitleColor: StyleParamType.Color;
  bodyBackgroundColor: StyleParamType.Color;
  bodyServiceTitleFont: StyleParamType.Font;
  bodyServiceTitleColor: StyleParamType.Color;
  bodyServiceTaglineFont: StyleParamType.Font;
  bodyServiceTaglineColor: StyleParamType.Color;
  bodyTitleFont: StyleParamType.Font;
  bodyTitleColor: StyleParamType.Color;
  bodyTextFont: StyleParamType.Font;
  bodyTextColor: StyleParamType.Color;
  bodyDividerWidth: StyleParamType.Number;
  bodyDividerColor: StyleParamType.Color;
  sidebarBackgroundColor: StyleParamType.Color;
  sidebarTitleColor: StyleParamType.Color;
  sidebarTitleFont: StyleParamType.Font;
  sidebarTextColor: StyleParamType.Color;
  sidebarTextFont: StyleParamType.Font;
  sidebarServiceTitleColor: StyleParamType.Color;
  sidebarServiceTitleFont: StyleParamType.Font;
  sidebarServiceTaglineColor: StyleParamType.Color;
  sidebarServiceTaglineFont: StyleParamType.Font;
  sidebarButtonTextColor: StyleParamType.Color;
  sidebarButtonTextFont: StyleParamType.Font;
  sidebarButtonBackgroundColor: StyleParamType.Color;
  sidebarButtonBorderColor: StyleParamType.Color;
  sidebarButtonBorderWidth: StyleParamType.Number;
  sidebarButtonCornerRadius: StyleParamType.Number;
  sidebarButtonHoverTextColor: StyleParamType.Color;
  sidebarButtonHoverBackgroundColor: StyleParamType.Color;
  sidebarButtonHoverBorderColor: StyleParamType.Color;
  sidebarSidePadding: StyleParamType.Number;
  headerButtonTextColor: StyleParamType.Color;
  headerButtonTextFont: StyleParamType.Font;
  headerButtonBackgroundColor: StyleParamType.Color;
  headerButtonBorderColor: StyleParamType.Color;
  headerButtonBorderWidth: StyleParamType.Number;
  headerButtonCornerRadius: StyleParamType.Number;
  headerButtonHoverTextColor: StyleParamType.Color;
  headerButtonHoverBackgroundColor: StyleParamType.Color;
  headerButtonHoverBorderColor: StyleParamType.Color;
  bodyButtonTextColor: StyleParamType.Color;
  bodyButtonTextFont: StyleParamType.Font;
  bodyButtonBackgroundColor: StyleParamType.Color;
  bodyButtonBorderColor: StyleParamType.Color;
  bodyButtonBorderWidth: StyleParamType.Number;
  bodyButtonCornerRadius: StyleParamType.Number;
  bodyButtonHoverTextColor: StyleParamType.Color;
  bodyButtonHoverBackgroundColor: StyleParamType.Color;
  bodyButtonHoverBorderColor: StyleParamType.Color;
  bodySidePadding: StyleParamType.Number;
  bodySpaceBetweenSections: StyleParamType.Number;
  firstSectionSpaceFromTheTop: StyleParamType.Number;
  serviceTitleFont: StyleParamType.Font;
  serviceTitleColor: StyleParamType.Color;
  serviceTaglineFont: StyleParamType.Font;
  serviceTaglineColor: StyleParamType.Color;
  backgroundColor: StyleParamType.Color;
  detailsInnerPadding: StyleParamType.Number;
  detailsBoxFont: StyleParamType.Font;
  detailsBoxTextColor: StyleParamType.Color;
  detailsBoxColor: StyleParamType.Color;
  detailsBoxBorderColor: StyleParamType.Color;
  detailsBoxBorderWidth: StyleParamType.Number;
}>({
  headerHeight: {
    type: StyleParamType.Number,
    key: 'headerHeight',
    getDefaultValue: () => 360,
  },
  headerSidePadding: {
    type: StyleParamType.Number,
    key: 'headerSidePadding',
    getDefaultValue: () => 20,
  },
  headerImageOpacity: {
    type: StyleParamType.Number,
    key: 'headerImageOpacity',
    getDefaultValue: () => 60,
  },
  bodyBackgroundColor: {
    type: StyleParamType.Color,
    key: 'bodyBackgroundColor',
    getDefaultValue: wixColorParam('color-1'),
  },
  headerBackgroundColor: {
    type: StyleParamType.Color,
    key: 'headerBackgroundColor',
    getDefaultValue: wixColorParam('color-5'),
  },
  headerTitleFont: {
    type: StyleParamType.Font,
    key: 'headerTitleFont',
    getDefaultValue: wixFontParam('Page-title', {
      size: 40,
    }),
  },
  headerTitleColor: {
    type: StyleParamType.Color,
    key: 'headerTitleColor',
    getDefaultValue: wixColorParam('color-1'),
  },
  bodyTitleFont: {
    type: StyleParamType.Font,
    key: 'bodyTitleFont',
    getDefaultValue: wixFontParam('Page-title', {
      size: 20,
    }),
  },
  bodyTitleColor: {
    type: StyleParamType.Color,
    key: 'bodyTitleColor',
    getDefaultValue: wixColorParam('color-5'),
  },
  bodyTextFont: {
    type: StyleParamType.Font,
    key: 'bodyTextFont',
    getDefaultValue: wixFontParam('Body-M', {
      size: 16,
    }),
  },
  bodyTextColor: {
    type: StyleParamType.Color,
    key: 'bodyTextColor',
    getDefaultValue: wixColorParam('color-5'),
  },
  bodySidePadding: {
    type: StyleParamType.Number,
    key: 'bodySidePadding',
    getDefaultValue: () => 20,
  },
  firstSectionSpaceFromTheTop: {
    type: StyleParamType.Number,
    key: 'firstSectionSpaceFromTheTop',
    getDefaultValue: () => 80,
  },
  bodySpaceBetweenSections: {
    type: StyleParamType.Number,
    key: 'bodySpaceBetweenSections',
    getDefaultValue: () => 32,
  },
  sidebarBackgroundColor: {
    type: StyleParamType.Color,
    key: 'sidebarBackgroundColor',
    getDefaultValue: wixColorParam('color-1'),
  },
  sidebarTitleColor: {
    type: StyleParamType.Color,
    key: 'sidebarTitleColor',
    getDefaultValue: wixColorParam('color-5'),
  },
  sidebarTitleFont: {
    type: StyleParamType.Font,
    key: 'sidebarTitleFont',
    getDefaultValue: wixFontParam('Page-title', {
      size: 20,
    }),
  },
  sidebarTextColor: {
    type: StyleParamType.Color,
    key: 'sidebarTextColor',
    getDefaultValue: wixColorParam('color-5'),
  },
  sidebarTextFont: {
    type: StyleParamType.Font,
    key: 'sidebarTextFont',
    getDefaultValue: wixFontParam('Body-M', {
      size: 16,
    }),
  },
  sidebarServiceTitleColor: {
    type: StyleParamType.Color,
    key: 'sidebarServiceTitleColor',
    getDefaultValue: wixColorParam('color-5'),
  },
  sidebarServiceTitleFont: {
    type: StyleParamType.Font,
    key: 'sidebarServiceTitleFont',
    getDefaultValue: wixFontParam('Page-title', {
      size: 20,
    }),
  },
  sidebarServiceTaglineColor: {
    type: StyleParamType.Color,
    key: 'sidebarServiceTaglineColor',
    getDefaultValue: wixColorParam('color-5'),
  },
  sidebarServiceTaglineFont: {
    type: StyleParamType.Font,
    key: 'sidebarServiceTaglineFont',
    getDefaultValue: wixFontParam('Body-M', {
      size: 16,
    }),
  },
  sidebarSidePadding: {
    type: StyleParamType.Number,
    key: 'sidebarSidePadding',
    getDefaultValue: () => 20,
  },
  serviceTitleFont: {
    type: StyleParamType.Font,
    key: 'serviceTitleFont',
    getDefaultValue: wixFontParam('Page-title', {
      size: 40,
    }),
  },
  serviceTitleColor: {
    type: StyleParamType.Color,
    key: 'serviceTitleColor',
    getDefaultValue: wixColorParam('color-5'),
  },
  serviceTaglineFont: {
    type: StyleParamType.Font,
    key: 'serviceTaglineFont',
    getDefaultValue: wixFontParam('Page-title', {
      size: 16,
    }),
  },
  serviceTaglineColor: {
    type: StyleParamType.Color,
    key: 'serviceTaglineColor',
    getDefaultValue: wixColorParam('color-5'),
  },
  backgroundColor: {
    type: StyleParamType.Color,
    key: 'backgroundColor',
    getDefaultValue: wixColorParam('color-5'),
  },
  detailsInnerPadding: {
    type: StyleParamType.Number,
    key: 'detailsInnerPadding',
    getDefaultValue: () => 16,
  },
  detailsBoxFont: {
    type: StyleParamType.Font,
    key: 'detailsBoxFont',
    getDefaultValue: wixFontParam('Body-M', {
      size: 16,
    }),
  },
  detailsBoxTextColor: {
    type: StyleParamType.Color,
    key: 'detailsBoxTextColor',
    getDefaultValue: wixColorParam('color-5'),
  },
  detailsBoxColor: {
    type: StyleParamType.Color,
    key: 'detailsBoxColor',
    getDefaultValue: wixColorParam('color-1'),
  },
  detailsBoxBorderColor: {
    type: StyleParamType.Color,
    key: 'detailsBoxBorderColor',
    getDefaultValue: wixColorParam('color-5', 0.2),
  },
  detailsBoxBorderWidth: {
    type: StyleParamType.Number,
    key: 'detailsBoxBorderWidth',
    getDefaultValue: () => 1,
  },
  sidebarButtonTextColor: {
    type: StyleParamType.Color,
    key: 'sidebarButtonTextColor',
    getDefaultValue: wixColorParam('color-1'),
  },
  sidebarButtonTextFont: {
    type: StyleParamType.Font,
    key: 'sidebarButtonTextFont',
    getDefaultValue: wixFontParam('Body-M', {
      size: 16,
    }),
  },
  sidebarButtonBackgroundColor: {
    type: StyleParamType.Color,
    key: 'sidebarButtonBackgroundColor',
    getDefaultValue: wixColorParam('color-8'),
  },
  sidebarButtonBorderColor: {
    type: StyleParamType.Color,
    key: 'sidebarButtonBorderColor',
    getDefaultValue: wixColorParam('color-1'),
  },
  sidebarButtonBorderWidth: {
    type: StyleParamType.Number,
    key: 'sidebarButtonBorderWidth',
    getDefaultValue: () => 1,
  },
  sidebarButtonCornerRadius: {
    type: StyleParamType.Number,
    key: 'sidebarButtonCornerRadius',
    getDefaultValue: () => 1,
  },
  sidebarButtonHoverTextColor: {
    type: StyleParamType.Color,
    key: 'sidebarButtonHoverTextColor',
    getDefaultValue: wixColorParam('color-1'),
  },
  sidebarButtonHoverBackgroundColor: {
    type: StyleParamType.Color,
    key: 'sidebarButtonHoverBackgroundColor',
    getDefaultValue: wixColorParam('color-8', 0.7),
  },
  sidebarButtonHoverBorderColor: {
    type: StyleParamType.Color,
    key: 'sidebarButtonHoverBorderColor',
    getDefaultValue: wixColorParam('color-1', 0.7),
  },
  bodyButtonTextColor: {
    type: StyleParamType.Color,
    key: 'bodyButtonTextColor',
    getDefaultValue: wixColorParam('color-1'),
  },
  bodyButtonTextFont: {
    type: StyleParamType.Font,
    key: 'bodyButtonTextFont',
    getDefaultValue: wixFontParam('Body-M', {
      size: 16,
    }),
  },
  bodyButtonBackgroundColor: {
    type: StyleParamType.Color,
    key: 'bodyButtonBackgroundColor',
    getDefaultValue: wixColorParam('color-8'),
  },
  bodyButtonBorderColor: {
    type: StyleParamType.Color,
    key: 'bodyButtonBorderColor',
    getDefaultValue: wixColorParam('color-1'),
  },
  bodyButtonBorderWidth: {
    type: StyleParamType.Number,
    key: 'bodyButtonBorderWidth',
    getDefaultValue: () => 1,
  },
  bodyButtonCornerRadius: {
    type: StyleParamType.Number,
    key: 'bodyButtonCornerRadius',
    getDefaultValue: () => 1,
  },
  bodyButtonHoverTextColor: {
    type: StyleParamType.Color,
    key: 'bodyButtonHoverTextColor',
    getDefaultValue: wixColorParam('color-1'),
  },
  bodyButtonHoverBackgroundColor: {
    type: StyleParamType.Color,
    key: 'bodyButtonHoverBackgroundColor',
    getDefaultValue: wixColorParam('color-8', 0.7),
  },
  bodyButtonHoverBorderColor: {
    type: StyleParamType.Color,
    key: 'bodyButtonHoverBorderColor',
    getDefaultValue: wixColorParam('color-1', 0.7),
  },
  headerButtonTextColor: {
    type: StyleParamType.Color,
    key: 'headerButtonTextColor',
    getDefaultValue: wixColorParam('color-8'),
  },
  headerButtonTextFont: {
    type: StyleParamType.Font,
    key: 'headerButtonTextFont',
    getDefaultValue: wixFontParam('Body-M', {
      size: 16,
    }),
  },
  headerButtonBackgroundColor: {
    type: StyleParamType.Color,
    key: 'headerButtonBackgroundColor',
    getDefaultValue: wixColorParam('color-1'),
  },
  headerButtonBorderColor: {
    type: StyleParamType.Color,
    key: 'headerButtonBorderColor',
    getDefaultValue: wixColorParam('color-8'),
  },
  headerButtonBorderWidth: {
    type: StyleParamType.Number,
    key: 'headerButtonBorderWidth',
    getDefaultValue: () => 1,
  },
  headerButtonCornerRadius: {
    type: StyleParamType.Number,
    key: 'headerButtonCornerRadius',
    getDefaultValue: () => 1,
  },
  headerButtonHoverTextColor: {
    type: StyleParamType.Color,
    key: 'headerButtonHoverTextColor',
    getDefaultValue: wixColorParam('color-1'),
  },
  headerButtonHoverBackgroundColor: {
    type: StyleParamType.Color,
    key: 'headerButtonHoverBackgroundColor',
    getDefaultValue: wixColorParam('color-1', 0.7),
  },
  headerButtonHoverBorderColor: {
    type: StyleParamType.Color,
    key: 'headerButtonHoverBorderColor',
    getDefaultValue: wixColorParam('color-8', 0.7),
  },
  bodyDividerWidth: {
    type: StyleParamType.Number,
    key: 'bodyDividerWidth',
    getDefaultValue: () => 1,
  },
  bodyDividerColor: {
    type: StyleParamType.Color,
    key: 'bodyDividerColor',
    getDefaultValue: wixColorParam('color-5', 0.2),
  },
  bodyServiceTitleFont: {
    type: StyleParamType.Font,
    key: 'bodyServiceTitleFont',
    getDefaultValue: wixFontParam('Page-title', {
      size: 40,
    }),
  },
  bodyServiceTitleColor: {
    type: StyleParamType.Color,
    key: 'bodyServiceTitleColor',
    getDefaultValue: wixColorParam('color-5'),
  },
  bodyServiceTaglineFont: {
    type: StyleParamType.Font,
    key: 'bodyServiceTaglineFont',
    getDefaultValue: wixFontParam('Body-M', {
      size: 20,
    }),
  },
  bodyServiceTaglineColor: {
    type: StyleParamType.Color,
    key: 'bodyServiceTaglineColor',
    getDefaultValue: wixColorParam('color-5'),
  },
});
