import React from 'react';
import {
  InjectedExperimentsProps,
  InjectedTranslateProps,
  translate,
  withExperiments,
} from 'yoshi-flow-editor-runtime';
import { st, classes } from './Widget.st.css';
import TitleAndTagline from './TitleAndTagline';
import Details from './Details';
import Contact from './Contact';
import { ISection, SectionTypes } from '../types';
import Header from './Header/Header';
import settingsParams from '../settingsParams';
import { ServicePageViewModel } from '../../../service-page-view-model/servicePageViewModel';
import EmptyState from './EmptyState';
import Description from './Description';
import Sidebar from './Sidebar/Sidebar';
import { Policy } from './Policy/Policy';
import { IHostProps } from '@wix/native-components-infra/dist/src/types/types';
import { TPAComponentsProvider } from 'wix-ui-tpa/TPAComponentsConfig';
import Body from './Body/Body';
import { WidgetBILoggerProvider } from '../bi/biContext';
import Section from './Section';
import { useSettings } from '@wix/tpa-settings/dist/src/hooks';

export interface ControllerProps {
  viewModel?: ServicePageViewModel;
  schedule?: any;
  className?: string;
  host: IHostProps;
  isMobile: boolean;
  isRTL: boolean;
}

type WidgetProps = InjectedExperimentsProps &
  InjectedTranslateProps &
  ControllerProps;

const getSectionComponentByType = (
  section: ISection,
  viewModel: ServicePageViewModel,
) => {
  switch (section.type) {
    case SectionTypes.TITLE_TAGLINE:
      return (
        <TitleAndTagline
          key={section.type}
          section={section}
          viewModel={viewModel.titleAndTagline}
        />
      );
    case SectionTypes.SCHEDULING:
      return (
        <Section key={section.type} sectionType={SectionTypes.SCHEDULING}>
          <div>scheduling</div>
        </Section>
      );
    case SectionTypes.POLICY:
      return <Policy key={section.type} viewModel={viewModel.policySection} />;
    case SectionTypes.DETAILS:
      return (
        <Details
          key={section.type}
          section={section}
          viewModel={viewModel.detailsSection}
        />
      );
    case SectionTypes.DESCRIPTION:
      return (
        <Description
          key={section.type}
          section={section}
          viewModel={viewModel.descriptionSection}
        />
      );
    case SectionTypes.CONTACT:
      return (
        <Contact
          key={section.type}
          section={section}
          viewModel={viewModel[SectionTypes.CONTACT]}
        />
      );
    default:
      return null;
  }
};

export default translate()(
  withExperiments<WidgetProps>(
    ({ viewModel, schedule, className, isMobile, isRTL }) => {
      const settings = useSettings();
      console.log(schedule);
      const getSectionByType = (sectionType: SectionTypes): ISection => {
        return settings
          .get(settingsParams.sections)
          .find(({ type }) => type === sectionType) as ISection;
      };

      const isSectionVisible = (section: ISection): boolean => {
        return (
          section.visible &&
          (!settings.get(settingsParams.sidebarVisibility) ||
            section.type !== settings.get(settingsParams.sidebarSection))
        );
      };

      return (
        <TPAComponentsProvider value={{ mobile: isMobile, rtl: isRTL }}>
          <WidgetBILoggerProvider>
            {viewModel ? (
              <div
                className={st(classes.root, {}, className)}
                data-hook="booking-service-page-wrapper"
              >
                {settings.get(settingsParams.headerVisibility) && (
                  <Header viewModel={viewModel.header} />
                )}
                <div
                  className={st(
                    classes.dynamicWrapper,
                    {
                      sidebarPosition: settings.get(
                        settingsParams.sidebarPosition,
                      ),
                      isMobile,
                    },
                    className,
                  )}
                >
                  <Body
                    width={`calc(${
                      100 - settings.get(settingsParams.sidebarWidth)
                    }% - ${settings.get(settingsParams.sidebarSideSpacing)}px)`}
                    bodyAlignment={settings.get(settingsParams.bodyAlignment)}
                  >
                    {settings
                      .get(settingsParams.sections)
                      .map((section) =>
                        isSectionVisible(section)
                          ? getSectionComponentByType(section, viewModel)
                          : null,
                      )}
                  </Body>
                  {settings.get(settingsParams.sidebarVisibility) && (
                    <Sidebar
                      width={`calc(${settings.get(
                        settingsParams.sidebarWidth,
                      )}% - ${settings.get(
                        settingsParams.sidebarSideSpacing,
                      )}px`}
                    >
                      {getSectionComponentByType(
                        getSectionByType(
                          settings.get(settingsParams.sidebarSection),
                        ),
                        viewModel,
                      )}
                    </Sidebar>
                  )}
                </div>
              </div>
            ) : (
              <EmptyState />
            )}
          </WidgetBILoggerProvider>
        </TPAComponentsProvider>
      );
    },
  ),
);
